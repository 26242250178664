<template>
  <el-container class="box">
    <el-container>
      <el-aside style="width:210px;height:100vh;background-color:#2DAB9F">
        <div class="left-title">
          <p>MILK</p>
          <p>晨光学生奶征订系统</p>
        </div>
        <el-scrollbar>
          <el-menu style="border-right:none!important;" :default-active="$route.fullPath" background-color="#2DAB9F" text-color="#fff" active-text-color="#D9B166" :unique-opened="true" @select="menuClick">
            <Submenu :list="menuTree"></Submenu>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <div class="box2">
        <div class="top-box">
          <span></span>
          <el-badge :value="noHandleFeedbackCount" class="feedback" :max="99" title="意见反馈" v-if="noHandleFeedbackCount" >
            <i class="el-icon-chat-dot-square" @click="goFeedback"></i>
          </el-badge>
          <el-dropdown>
            <span class="el-dropdown-link">
              {{sysName}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="changePwd">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="logOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="view-box">
          <!-- <keep-alive :include="keepList"> -->
          <router-view :key="$route.fullPath"></router-view>
          <!-- </keep-alive> -->
          <!-- <keep-alive :include="keepList">
              <router-view  v-if="$route.meta.keepAlive" ></router-view>
            </keep-alive>
            <router-view  v-if="!$route.meta.keepAlive" ></router-view>  -->
        </div>
      </div>
    </el-container>
    <ChangePassword v-model="changePwdDialog"></ChangePassword>
  </el-container>
</template>
<script>
import { getNoHandleFeedbackCount } from '@/api/index.js'
import ChangePassword from '@/components/ChangePassword/index'
import Submenu from '@/components/Submenu/index'
import { mapGetters } from 'vuex'
export default {
  name: "index",
  components: { ChangePassword, Submenu },
  data() {
    return {
      TabsMenuValue: "/default",
      TabsMenuArr: [],
      changePwdDialog: false,
      sysName: '',
      organizationName: '',
      menuTree: [],
      noHandleFeedbackCount:0
    };
  },
  computed: {
    ...mapGetters(['keepList'])
  },
  created() {
    this.menuTree = JSON.parse(sessionStorage.getItem('menuList'))
    let user = JSON.parse(sessionStorage.getItem('user')) || {}
    this.sysName = user.name
    this.organizationName = user.organizationOrgName
    this.getNoHandleFeedbackCount();
  },
  methods: {
    goFeedback(){
      this.$router.push({path:'/service/feedback'});
    },
    getNoHandleFeedbackCount(){
      getNoHandleFeedbackCount({}).then((res)=>{
        this.noHandleFeedbackCount = res.data;
      })
    },
    changePwd() {
      this.changePwdDialog = true;
    },
    // 左侧菜单 点击跳转
    async menuClick(path) {
      this.getMenu(this.menuTree, path)
      this.$router.push({ path: path });
    },
    getMenu(list, path) {
      list.map(item => {
        if (item.path == path && path != '/default') {
          this.$store.commit('addMenuList', item)
          return
        }
        if (item.child && item.child.length) {
          this.getMenu(item.child, path)
        }
      });
    },
    //退出登录
    logOut() {
      this.TabsMenuArr = [];
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('x-token')
      this.$router.replace('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
$themeColor: #0099ff;
.box {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .box2 {
    width: 100%;
    overflow: auto;
    // padding: 10px;
    box-sizing: border-box;
    border-left: 1px solid #eee;
  }
}
.feedback{
  margin-right: 30px;
  cursor: pointer;
}
.el-dropdown {
  cursor: pointer;
}
.bar-logo {
  height: 54px;
}
.menu-title {
  font-size: 16px;
  line-height: 45px;
}
.main-box {
  overflow: hidden;
  height: 100%;
}
.el-container {
  height: 100%;
}
.left-title {
  margin: 40px 0 50px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  p:nth-child(1) {
    font-size: 24px;
  }
}
.top-box {
  box-sizing: border-box;
  // background-color: $themeColor;
  font-size: 20px;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  justify-content:right;
  height: 60px;
  width: 100%;
  padding: 0 10px;
}
.view-box {
  box-sizing: border-box;
  background: #f0f2fa;
  padding: 10px;
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.el-header span {
  color: #ffffff;
}
.el-tab-pane {
  font-size: 16px;
}

.el-container .el-aside,
.el-menu {
  color: #333;
}
.el-main {
  background-color: #ffffff;
  color: #333;
  // text-align: center;
  padding-top: 5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  border-left: 1px solid #f1f1f1;
  min-height: 730px;
}
.el-submenu,
.el-menu-item {
  text-align: left;
}
.el-submenu__title {
  padding-left: 10px !important;
}
.el-menu-item-group {
  overflow-x: hidden;
  // background: #000;
}
.el-menu-item-group .el-menu-item {
  padding-left: 34px !important;
}
.el-menu-item {
  text-align: left;
  padding: 0px 0px 0px 20px !important;
}
.el-submenu .el-menu-item {
  min-width: 154px;
}
.el-menu-item.is-active {
  border-right: 4px solid $themeColor !important;
}
body > .el-container {
  margin-bottom: 40px;
}
</style>