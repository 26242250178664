<template>
  <el-dialog :destroy-on-close="true" title="修改密码" :visible.sync="value" :lock-scroll="false" :close-on-click-modal="false" width="440px" :before-close="beforeClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="200px;">
      <el-form-item label="请输入新的密码" prop="confirmPwd">
        <el-input v-model="form.confirmPwd" maxlength="16" @input="changeCheck('confirmPwd')" placeholder="请输入用户密码(字母或数字组合,长度小于16位)" style="width:280px;"></el-input>
      </el-form-item>
      <el-form-item label="再次输入新密码" prop="newPwd">
        <el-input v-model="form.newPwd" maxlength="16" @input="changeCheck('newPwd')" placeholder="请输入用户密码(字母或数字组合,长度小于16位)" style="width:280px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" v-debounce="changePwdSubmit">确认修改</el-button>
        <el-button @click="beforeClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { changePwd } from "@/api/index";
export default {
  name: 'ChangePassword',
  props: {
    value: Boolean
  },
  data() {
    let checkPwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不可为空"));
      }
      if (value.length < 6) {
        return callback(new Error("密码至少6位以上"));
      }
      callback();
    };
    return {

      rules: {
        confirmPwd: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: checkPwd, trigger: "blur" },
        ],
        newPwd: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: checkPwd, trigger: "blur" },
        ],
      },
      form: {
        confirmPwd: "",
        newPwd: "",
      },
    }
  },
  methods: {
    changeCheck(checkKey) {
      this.$nextTick(() => {
        this.form[checkKey] = this.form[checkKey].replace(
          /[^\w\*\.\&\@\!\#\$\%\^\(\)\-\=\+\/]/gi,
          ""
        );
      });
    },
    changePwdSubmit() {
      if (!this.$validate('form')) return
      let form = JSON.parse(JSON.stringify(this.form));
      form.id = JSON.parse(sessionStorage.getItem('user')).id
      if (form.confirmPwd != form.newPwd) {
        this.$message.error({
          title: "错误",
          message: "两次密码不一致",
        });
        return;
      }
      changePwd(form).then((response) => {
        if (response.code == 200) {
          this.beforeClose()
          this.$message({
            message: "修改成功，请稍后重新登录！",
            type: "success",
            duration: 1500,
          });
          setTimeout(() => {
            this.$emit('success');
          }, 2000);
        } else {
          this.$message.error({
            title: "错误",
            message: response.msg || response.error_msg,
            duration: 1500,
          });
        }
      });
    },
    beforeClose() {
      this.$emit('input', false)
    }
  }
}
</script>

<style>
</style>